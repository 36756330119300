import { StyleSheet, View } from 'react-native'
import React, { useContext } from 'react'

import { FormikProvider } from 'formik'
import { CommonContext } from '@contextState/common'
import { LanguageContext } from '@contextState/language'
import FormCompany from '../../../components/FormCompany'
import { ScrollView } from 'react-native-gesture-handler'

import ButtonPrimary from '@components/buttons/ButtonPrimary'

import {
  white,
  primary500,
  primary50,
  blackHighEmphasis,
} from '@styles/palette'
import { useCompanyEdit } from './hooks/useCompanyEdit'
import { useAuth } from '@common/hooks'
import { useCountries } from '@common/hooks/useCountries'

const EditCompany = ({ navigation }) => {
  const { t } = useContext(LanguageContext)
  const { countriesData, formik, onPressAddFile, onDeleteFile } =
    useCompanyEdit({ t })
  const { allCountryData } = useCountries()
  const { config, isCompanyUcropit, isVerifierUcropit } = useAuth()
  const isUcropit = isCompanyUcropit || isVerifierUcropit
  const verifyIsAdviser =
    isUcropit &&
    config?.companyAdmin &&
    config?.companyAdmin?._id !== config?.companySelected?._id

  const { handleSubmit, isValid, dirty, isSubmitting } = formik

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={styles.container}>
        <FormikProvider value={formik}>
          <FormCompany
            formik={formik}
            isAdviser={verifyIsAdviser}
            countriesData={countriesData}
            allCountryData={allCountryData}
            onDeleteFile={onDeleteFile}
            onPressAddFile={onPressAddFile}
            toEditCompany={true}
          />
        </FormikProvider>
        <ButtonPrimary
          text={t('VIEWS.COMPANY_EDIT.SAVE_COMPANY').toUpperCase()}
          backgroundColor={primary500}
          colorText={white}
          marginTop={50}
          marginBottom={0}
          onPress={handleSubmit}
          disabled={!isValid || !dirty || isSubmitting}
        />
        <ButtonPrimary
          text={t('VIEWS.COMPANY_EDIT.CANCEL').toUpperCase()}
          backgroundColor={white}
          borderColor={primary500}
          colorText={primary500}
          marginTop={12.5}
          marginBottom={40}
          onPress={() => navigation.goBack()}
        />
      </ScrollView>
    </View>
  )
}

export default EditCompany

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: white,
  },
  collaboratorsContainer: {
    marginTop: 5,
  },
  adviserTextContainer: {
    height: 70,
    marginTop: 30,
    backgroundColor: primary50,
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 8,
    color: blackHighEmphasis,
  },
  adviserText: {
    fontSize: 12,
    color: blackHighEmphasis,
  },
})
