import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/**
 * @property {string} url
 * @property {func} onMessage
 */
const initialState = {
  countries: [],
}

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<any>) => {
      state = {
        ...state,
        countries: action.payload,
      }
      return state
    },
    resetCountries: (state) => {
      state = initialState
      return state
    },
  },
})

export const { setCountries, resetCountries } = countriesSlice.actions

export const { reducer: countriesReducer } = countriesSlice
