import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { Card } from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import ButtonCustom from '@components/common/ButtonCustom'
import { SelectorContext } from '@contextState/selectors'
import useNetwork from '@utils/network'
import { CommonContext } from '@contextState/common'
import { LanguageContext } from '@contextState/language'
import userTypes from '@constants/userTypes'
import activityTypes from '@constants/activityTypes'
import { useSelector } from 'react-redux'
import useOfflineCrops from '@offline/queries/crops'
import { ActivityIndicator } from '@modules/common/components'
import { gray80 } from '@common/styles'
import InputCheckbox from '@components/inputs/InputCheckbox'
import { useAuth } from '@common/hooks'

function CollaboratorsSelectorScreen({ route, navigation }) {
  const { doRequest } = useNetwork()
  const { user, selectedProfile } = useAuth()
  const { setCollaboratorSelector, collaboratorSelector } =
    useContext(SelectorContext)
  const [collaborators, setMembers] = useState([])
  const [selected, setSelected] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [canWrite, setCanWrite] = useState(false)
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { roleTypes } = useSelector((state) => state.rolesReducer)
  const { findOneCropById } = useOfflineCrops()
  const { tag } = useRef(route.params).current
  const { t } = useContext(LanguageContext)

  const fetchCrop = useCallback(async () => {
    setIsLoading(true)
    let me = {}

    let members = []

    if (isConnected) {
      const { config } = user
      const response = await doRequest({
        method: 'GET',
        version: 'v2',
        url: `crops/${route.params.id}?companyId=${config.companySelected._id}`,
      })
      me = response.data.members.find((el) => el.user._id === user._id)

      setCanWrite(me.type !== 'MARKETER' && me.type !== 'PROVIDER')
      setUserDefaultSelected(me)

      members = response.data.members.filter(
        ({ hasPendingOrRejectedCRequest }) => !hasPendingOrRejectedCRequest
      )
    } else {
      const offlineData = await findOneCropById(route.params.id)

      me = offlineData.members.find((el) => el.user._id === user._id)

      setUserDefaultSelected(me)

      members = offlineData.members.filter(
        ({ hasPendingOrRejectedCRequest }) => !hasPendingOrRejectedCRequest
      )
    }

    if (tag === activityTypes.ACT_VERIFICATION.key) {
      if (me.type === userTypes.VERIFIER.key) {
        members = members.filter(
          (element) =>
            element.type === userTypes.VERIFIER.key &&
            element.identifier === me.identifier
        )
      } else {
        members = members.filter(
          (element) => element.type === userTypes.VERIFIER.key
        )
      }
    } else {
      members = members.filter(
        (element) =>
          element.type !== userTypes.MARKETER.key &&
          element.type !== userTypes.PROVIDER.key &&
          element.type !== userTypes.VERIFIER.key
      )
    }

    setMembers(members)

    setCanWrite(
      me.type !== userTypes.MARKETER.key &&
        me.type !== userTypes.PROVIDER.key &&
        me.type !== userTypes.VERIFIER.key
    )

    setIsLoading(false)
  }, [])

  useEffect(() => {
    setSelected(collaboratorSelector)
  }, [collaboratorSelector])

  useEffect(() => {
    fetchCrop()
  }, [isConnected])

  function setUserDefaultSelected(userLogin) {
    const selectedDefault = []
    if (route.params.status !== 'readOnly' && route.params.status !== 'done') {
      if (
        (userLogin.type !== 'MARKETER' &&
          userLogin.type !== 'PROVIDER' &&
          collaboratorSelector.length === 0) ||
        (selectedProfile.isAdmin && collaboratorSelector.length === 0)
      ) {
        if (!isExistCollaborator(userLogin.user)) {
          selectedDefault.push({
            userId: userLogin.user._id,
            fullName: `${userLogin.user.firstName} ${userLogin.user.lastName}`,
            email: userLogin.user.email,
            type: userLogin.type || 'Colaborador',
            identifier: userLogin.identifier,
          })
        }
      }
      if (collaboratorSelector.length > 0) {
        collaboratorSelector.forEach((element) => {
          selectedDefault.push({
            userId: element.userId,
            fullName: element.fullName,
            email: element.email,
            type: element.type || 'Colaborador',
            identifier: element.identifier,
          })
        })
      }

      setSelected(selectedDefault)
    }
  }

  function isExistCollaborator(user) {
    return collaboratorSelector.find(
      (collaborator) => collaborator.userId === user._id
    )
  }

  function handleCheck(collaborator) {
    const { user, type, identifier } = collaborator
    const found = selected.find((el) => el.userId === user._id)

    if (!found) {
      const selectedUsers = [
        ...selected,
        {
          userId: user._id,
          fullName: `${user.firstName} ${user.lastName}`,
          email: user.email,
          type,
          identifier,
        },
      ]
      setSelected(selectedUsers)
    } else {
      const filteringUsers = selected.filter((el) => el.userId !== user._id)
      setSelected(filteringUsers)
    }
  }

  function handleSelect() {
    setCollaboratorSelector(selected)
    if (route.params.callback) {
      route.params.callback(selected)
      navigation.goBack()
    } else if (route.params.valuesParams && route.params.routeRedirect) {
      const valuesForm = route.params.valuesParams.valuesForm
      valuesForm.signers = selected
      navigation.navigate(route.params.routeRedirect, {
        ...route.params.valuesParams,
        valuesForm,
      })
    } else {
      navigation.goBack()
    }
  }

  if (isLoading) {
    return (
      <ActivityIndicator
        size='large'
        containerStyle={styles.activityIndicator}
      />
    )
  }

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scroll}>
        {collaborators.map((el, key) => {
          return (
            <Card key={key} style={styles.card}>
              <Card.Title
                left={(props) => {
                  return (
                    <InputCheckbox
                      checked={Boolean(
                        selected.find(
                          (selection) => selection.userId === el.user._id
                        )
                      )}
                      colorUnchecked={gray80}
                      disabled={route.params.status === 'readOnly'}
                      onPress={() => handleCheck(el)}
                    />
                  )
                }}
                right={(props) => {
                  const found =
                    selected.find(
                      (selection) => selection.userId === el.user._id
                    ) || {}

                  return (
                    <CommonIcon
                      {...props}
                      style={{ marginRight: 16 }}
                      size={20}
                      name={found.signed ? 'SIGN' : ''}
                      color={found.signed ? 'green' : 'orange'}
                    />
                  )
                }}
                title={
                  <>
                    {el?.user?.firstName
                      ? `${el?.user?.firstName} ${el?.user?.lastName}`
                      : el.user.email}
                    {user._id === el.user._id ? ' (yo)' : ''}
                  </>
                }
                subtitleNumberOfLines={1}
                subtitle={`${el.identifier} - ${
                  roleTypes.find(
                    (collaborator) => collaborator.value === el.type
                  )?.label
                }`}
              />
            </Card>
          )
        })}
      </ScrollView>
      {route.params.status !== 'readOnly' && (
        <View style={styles.footer}>
          {(selectedProfile.isAdmin || canWrite) && (
            <ButtonCustom onPress={handleSelect} disabled={!selected.length}>
              {t('VIEWS').COLLABORATORS.TEXT_2}
            </ButtonCustom>
          )}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    marginBottom: 16,
  },
  container: {
    flex: 1,
    padding: 16,
  },
  scroll: {},
  section: {
    padding: '0',
  },
  Button: {
    marginBottom: 25,
    marginRight: 10,
    alignItems: 'center',
  },
  activityIndicator: {
    flex: 1,
    marginBottom: 20,
    marginTop: 10,
  },
})

export default CollaboratorsSelectorScreen
