import { useContext, useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/native'
import { v4 as uuidv4 } from 'uuid'
import { useFormik } from 'formik'
import moment from 'moment'

import useNetwork from '@utils/network'
import { CommonContext } from '@contextState/common'
import {
  getFormikInitialValues,
  getFormikValidationSchema,
  parseDataToSave,
} from '../utils'
import { collaboratorsCompanyActions } from '@store/actions'
import { identifierName } from '@utils/countries'
import { MAGIC_TIME_REDIRECT } from '@constants/magic'
import RouterNames from '@constants/routerNames'
import { removeEmailOfWelcomeCompanyList } from '@store/slices'
import { useAuth } from '@common/hooks'
import { useCountries } from '@common/hooks/useCountries'

export const useCompanyAdd = ({ isAdviser, t }) => {
  const dispatch = useDispatch()
  const { doRequest } = useNetwork()
  const navigation = useNavigation()
  const route = useRoute()
  const { countries } = useSelector((state) => state.countriesReducer)
  const { allCountryData } = useCountries()
  const { config, fetchUser, user } = useAuth()
  const { isConnected } = useSelector((state) => state.connectionReducer)
  let finish = false
  const [errorIdentifier, setErrorIdentifier] = useState(null)

  const [countriesData, setCountriesData] = useState([])
  const [isModalSuccess, setIsModalSuccess] = useState(false)

  const isNewUser = !config?.companyAdmin && !config?.companySelected
  const { onBoardingCompany } = route.params

  useEffect(() => {
    if (isModalSuccess) {
      setTimeout(() => {
        if (!finish) {
          navigateHome()
        }
        navigation.addListener('beforeRemove', (e) => {
          e.preventDefault()
          return
        })
      }, MAGIC_TIME_REDIRECT)
    }
  }, [isModalSuccess])

  useEffect(() => {
    if (!isConnected) {
      navigation.popToTop()
    }
  }, [isConnected])

  const navigateHome = () => {
    finish = true
    navigation.reset({
      index: 0,
      routes: [{ name: RouterNames.FARMS }],
    })
    setIsModalSuccess(false)
  }

  useEffect(() => {
    if (!countries.length) {
      return
    }

    setCountriesData(
      countries.map((element) => {
        return {
          label: element.name,
          value: element._id,
        }
      })
    )
  }, [countries])

  const formik = useFormik({
    initialValues: getFormikInitialValues(),
    onSubmit: (values) => {
      saveCompany(values)
    },
    validationSchema: getFormikValidationSchema(t, countries),
  })
  const { values, setFieldValue, resetForm, setFieldError, setSubmitting } =
    formik

  const onChangeCompany = async (company) => {
    const data = {
      companySelected: company._id,
      companyAdmin: isAdviser ? config?.companyAdmin?._id : null,
    }

    await doRequest({
      method: 'PUT',
      url: `configurations/${config._id}`,
      data,
    })
    await fetchUser()
    return true
  }

  const saveCompany = async (valuesFormik) => {
    try {
      const data = parseDataToSave(valuesFormik)
      const company = await doRequest({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        url: isNewUser ? 'users/companies' : 'companies',
        version: isNewUser ? 'v2' : 'v1.1',
        data: data,
      })
      const companyData = company.data
      if (!onBoardingCompany) {
        const exist =
          companyData.collaborators.find(
            (collaborator) => collaborator.user === user._id
          ) || companyData.users.find((userData) => userData.user === user._id)
        if (exist) {
          await onChangeCompany(company.data)
        }
        dispatch(removeEmailOfWelcomeCompanyList(user?.email))
        setIsModalSuccess(true)
      } else {
        navigation.navigate('SuccessAddCompany', {
          companyId: companyData._id,
        })
      }
    } catch (error) {
      console.error('error alta de empresa', error)
      setSubmitting(false)
    }
  }

  const onPressAddFile = (valuesFile) => {
    const fileExtension = valuesFile.name.substring(
      valuesFile.name.lastIndexOf('.'),
      valuesFile.name.length
    )
    const evidenceFileName = uuidv4() + fileExtension
    const resultFiles = [
      ...values.files,
      {
        file: valuesFile,
        type: valuesFile.type,
        name: valuesFile.name,
        fileNamePath: evidenceFileName,
        originalName: valuesFile.name,
        date: moment.isMoment(valuesFile.date)
          ? valuesFile.date.toDate()
          : moment(),
        user: {
          _id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
        },
      },
    ]
    setFieldValue('files', resultFiles)
  }

  const onDeleteFile = (key) => {
    const newFiles = values.files.filter((_, index) => index !== key)
    setFieldValue('files', newFiles)
  }

  const onChangeCountry = async (value) => {
    try {
      if (values?.country?.value) {
        resetForm()
      }
      if (!isAdviser) {
        const collaboratorCountry = await doRequest({
          method: 'GET',
          url: `countries/${value.value}/responsible-advisor`,
          version: 'v2',
        })
        const newUser = JSON.parse(JSON.stringify(user))
        newUser.config.isAdmin = true
        newUser.role = { value: 'PRODUCER', isAdmin: true }
        newUser.isAdmin = true
        const collaborators = [
          newUser,
          { ...collaboratorCountry.data, isResponsible: true },
        ]
        setFieldValue('users', collaborators)
      }
    } catch (error) {
      console.error('error Collaborator country', error)
    }
  }

  const addUserCollaborator = (users) => {
    const company = {
      companyName: values.name,
      companyIdentifier: values.identifier,
    }
    dispatch(
      collaboratorsCompanyActions.setCollaboratorsCompany({
        collaborators: users,
        company,
      })
    )
  }

  const navigateListCollaborator = () => {
    if (isAdviser && !values.users.length) {
      navigation.navigate('AddCollaborator', {
        isAdviser,
        callbackAddCollaborator,
        isNewCompany: true,
        identifierCompany: values.identifier,
        country: values.country,
        nextButton: true,
      })
    } else {
      addUserCollaborator(values.users)
      navigation.navigate('CollaboratorsListNewCompany')
    }
  }

  const callbackAddCollaborator = ({ valuesUser, params, setSubmitting }) => {
    if (!params.isSecond) {
      valuesUser.role = { value: 'PRODUCER', isAdmin: true }
      valuesUser.isAdmin = true
      setSubmitting(false)
      navigation.navigate('AddAdviser', {
        callbackAddCollaborator: callbackAddCollaborator,
        isAdviser,
        isNewCompany: true,
        isSecond: true,
        firstUser: valuesUser,
        identifierCompany: config?.companyAdmin?.identifier,
        country: values.country,
      })
    } else {
      valuesUser.role = { value: 'ADVISER', isResponsible: true }
      valuesUser.isResponsible = true
      valuesUser.companyName = config?.companyAdmin?.name
      setFieldValue('users', [params.firstUser, valuesUser])
      setSubmitting(false)
      navigation.pop(2)
    }
  }

  const onEndEditingIdentifier = async () => {
    try {
      const identifier = values.identifier
      const response = await doRequest({
        method: 'GET',
        url: `companies?identifier=${identifier}`,
        displayAlert: false,
      })
      if (response?.data?.length) {
        const labelIdentifier = identifierName(
          allCountryData(values?.country?.value)?.alpha3Code,
          t
        )
        setFieldError(
          'identifier',
          t('VIEWS.ADD_COMPANY_SCREEN.EXIST_IDENTIFIER', {
            labelIdentifier: String(labelIdentifier).toLocaleLowerCase(),
          })
        )
        setErrorIdentifier(
          t('VIEWS.ADD_COMPANY_SCREEN.EXIST_IDENTIFIER', {
            labelIdentifier: String(labelIdentifier).toLocaleLowerCase(),
          })
        )
      } else {
        setErrorIdentifier(null)
      }
    } catch (error) {
      console.error('error', error)
    }
  }
  const canPressCollaborator =
    values?.country?.value.length &&
    values?.identifier !== '' &&
    values?.name != '' &&
    !errorIdentifier

  return {
    countriesData,
    formik,
    isModalSuccess,
    onPressAddFile,
    onDeleteFile,
    onChangeCountry,
    navigateListCollaborator,
    onEndEditingIdentifier,
    canPressCollaborator,
    errorIdentifier,
    navigateHome,
  }
}
