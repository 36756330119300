import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { PropTypes } from 'prop-types'
import { CommonIcon, InputSelectDefault } from '@modules/common/components'
import { SelectorContext } from '@contextState/selectors'
import InputText from '@components/inputs/InputText'
import InputDate from '@components/inputs/InputDate'
import {
  filterSubActivityTypesByType,
  findActivitiesInCrop,
} from '@utils/common'
import { round } from 'lodash'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import useOfflineSubTypeActivities from '../../offline/queries/subTypeActivities'
import { CropContext } from '@contextState/crop'
import { black } from '@styles/palette'
import { truncateSurface } from '@common/utils/truncateSurface'
import { useSelector } from 'react-redux'

function AchievementsForm({
  route,
  navigation,
  setFieldValue,
  readOnly,
  values,
  activityType,
  setFieldTouched,
  errors,
  touched,
}) {
  const { supplySelector, setSupplySelector, lotSelectorTotalSurface } =
    useContext(SelectorContext)

  const { subTypeActivities } = useSelector(
    (state) => state.activityTypesReducer
  )
  const { currentCrop, setCurrentCropId } = useContext(CropContext)
  const { t } = useContext(LanguageContext)
  const { getOfflineSubTypeActivities } = useOfflineSubTypeActivities()
  const [filteredSubTypes, setFilteredSubTypes] = useState([])
  const { isConnected } = useSelector((state) => state.connectionReducer)

  useEffect(() => {
    let filteredSubTypes = []
    if (isConnected) {
      if (subTypeActivities.length) {
        filteredSubTypes = filterSubActivityTypesByType(
          subTypeActivities,
          activityType?.value
        )
      }
    } else {
      getOfflineSubTypeActivities().then((subTypes) => {
        if (subTypes.length) {
          subTypes = filterSubActivityTypesByType(subTypes, activityType.value)
          filteredSubTypes = subTypes.map(
            ({ _id: value, codeLabel: label, activityType }) => {
              return {
                label,
                activityType,
                value,
              }
            }
          )

          setFilteredSubTypes(filteredSubTypes)
        }
      })
    }

    setFilteredSubTypes(filteredSubTypes)

    if (route && currentCrop) {
      setCurrentCropId(route?.params?.id)

      let subTypeActivity = values?.subTypeActivity

      if (!subTypeActivity) {
        const activity = findActivitiesInCrop(
          currentCrop,
          route.params?.activity
        )

        subTypeActivity = filteredSubTypes.find(
          ({ value }) => value === activity?.subTypeActivity?._id
        )
      }

      setFieldValue('subTypeActivity', subTypeActivity)
    }
  }, [activityType, route, currentCrop])

  useEffect(() => {
    const surfaceDetail = values.surface ? Number(values.surface) : 0
    if (supplySelector.length && surfaceDetail) {
      const UpdateQuantitySurface = supplySelector.map((supply) => {
        return {
          ...supply,
          quantity: round(Number(supply.total) / Number(surfaceDetail), 6),
          surfaceDetail,
        }
      })
      setSupplySelector(UpdateQuantitySurface)
    }
  }, [values.surface])

  useEffect(() => {
    setFieldValue(
      'surface',
      readOnly
        ? values.surface > 0
          ? String(values.surface)
          : ''
        : lotSelectorTotalSurface > 0
        ? String(lotSelectorTotalSurface)
        : ''
    )
  }, [lotSelectorTotalSurface])

  const navigateLots = () => {
    if (readOnly) {
      navigation.navigate('ActivityMinimumUnitLot', {
        cropId: route.params.id,
        activityId: route.params.activity,
        readOnly,
      })

      return
    }

    navigation.navigate('ActivityLotsAdd', {
      cropId: route.params.id,
      activityId: route.params.activity,
      valuesParams: {
        id: route.params.id,
        activity: route.params.activity,
        tag: route.params.tag,
        draftId: route.params.draftId,
        valuesForm: values,
      },
      routeRedirect: 'AchievementsScreen',
    })
  }

  return (
    <View style={readOnly ? styles.disabledContainer : styles.container}>
      <InputSelectDefault
        id='subTypeActivity'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('subTypeActivity', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('subTypeActivity'))}
        value={filteredSubTypes.length ? values.subTypeActivity : null}
        label={`${t('COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_10')} ${
          route.params.tag
            ? t('CONSTANTS').ACTIVITY_TYPES[route.params.tag]
            : ''
        }`}
        placeholder={`${t('COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_10')} ${
          route.params.tag
            ? t('CONSTANTS').ACTIVITY_TYPES[route.params.tag]
            : ''
        }`}
        options={filteredSubTypes}
        touched={touched.country}
        error={errors?.subTypeActivity?.value}
        disabled={readOnly}
      />
      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={20}
            color={black}
            onPress={navigateLots}
          />
        }
        value={values.lots.label}
        onPress={() => {
          navigateLots()
          setFieldTouched('lots')
        }}
        editable={false}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_1.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_1.LABEL')}`}
        touched={touched.lots}
        error={errors.lots?.value}
        disabled={readOnly}
      />
      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={`${t('COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_3')} ${
          currentCrop?.areaUnit
        }`}
        label={`${t('COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_3')} ${
          currentCrop?.areaUnit
        }`}
        editable={false}
        disabled={true}
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />
      <InputDate
        id='dateAchievement'
        onChangeText={(value) => {
          setFieldValue('dateAchievement', value)
        }}
        value={values.dateAchievement}
        label={t('COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_4')}
        placeholder={t('COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_4')}
        disablePast={false}
        touched={touched.dateAchievement}
        error={errors?.dateAchievement?.value}
        onBlur={() => setTimeout(() => setFieldTouched('dateAchievement'))}
        editable={!readOnly}
        disabled={readOnly}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 5,
  },
  disabledContainer: {
    paddingTop: 5,
    opacity: 0.7,
  },
})

AchievementsForm.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
  setFieldValue: PropTypes.func,
  readOnly: PropTypes.bool,
  values: PropTypes.object,
  activityType: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default AchievementsForm
