import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/**
 * @property {string} url
 * @property {func} onMessage
 */
const initialState = {
  roleTypes: [],
}

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    /**
     * @param {object} state
     * @property {string} state.url
     * @property {func} state.onMessage
     *
     * @param {object} action
     * @property {object} action.payload
     * @property {string} action.payload.url
     * @property {func} action.payload.onMessage
     */
    setRoles: (state, action: PayloadAction<any>) => {
      state = {
        ...state,
        roleTypes: action.payload,
      }
      return state
    },
    resetRoles: (state) => {
      state = initialState
      return state
    },
  },
})

export const { setRoles, resetRoles } = rolesSlice.actions

export const { reducer: rolesReducer } = rolesSlice
