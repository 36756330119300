export default {
  TEXT_1: 'Sin permisos de la camara',
  TEXT_2: 'Por favor vaya a la configuración y activar manualmente',
  TEXT_3: 'Cancelar',
  TEXT_4: 'Permitir',
  TEXT_5:
    'El archivo que intentas subir no es un tipo de formato KMZ o se ha dañado.',
  TEXT_6: '2 Las extensiones permitidas serian .doc,.docx,.pdf,.png,.jpg,.zip',
  TEXT_7: 'La extensión del archivo no está permitida',
  TEXT_8: 'ACEPTAR',
  TEXT_9: 'UCROP.IT necesita permisos de localización',
  TEXT_10: 'Documentos',
  TEXT_11: 'Cámara',
  TEXT_12: 'Tablero',
  TEXT_13: 'Agregar evidencia',
  TEXT_14: 'Buscando georreferencia...',
  TEXT_15: 'Por favor, no salir de esta pantalla',
}
