import React, { useState, useEffect, useRef, useContext } from 'react'
import {
  StyleSheet,
  View,
  Text,
  Animated,
  FlatList,
  Platform,
} from 'react-native'
import { Input } from 'react-native-elements'
import { TouchableRipple } from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import {
  blackHighEmphasis,
  primary500,
  blackMediumEmphasis,
  onPressedColor,
  redError,
  buttonDisabled,
  borderInput,
  white,
  placeholderDisabled,
  grayDDD,
} from '@styles/palette'
import useFadeAnimation from '@hooks/useFadeAnimation'
import { isEmptyValue } from '@utils/common'
import { MAGIC_SPACE_DROPDOWN, MAGIC_RESULT_MOBILE } from '@constants/magic'
import { CommonContext } from '@contextState/common'
import { FlagCountry } from '../../images/FlagCountry'
import { MenuDefault } from '@common/components/menu/MenuDefault'
import { IValueCodeArea, InputPhoneProps } from '../InputPhone/inputsPhone.prop'
import { SIZES } from '@common/components/icons/interfaces'
import { GlobalStateInterface } from '@store/interfaces'
import { useSelector } from 'react-redux'

export const InputPhoneWeb = ({
  id,
  name,
  containerStyle = {},
  inputStyle = {},
  onChangeCodeArea,
  onChangePhoneNumber,
  valueCodeArea = {},
  valuePhone = '',
  inputRef,
  editable = true,
  disabled = false,
  placeholder = '',
  label = '',
  touched,
  error,
  onBlur,
}: InputPhoneProps) => {
  const { countries } = useSelector(
    (state: GlobalStateInterface) => state.countriesReducer
  )
  const [isFocused, setIsFocused] = useState(false)
  const [containerLayout, setContainerLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  })

  const placeholderFadeParams = useRef({
    durationIn: 200,
    durationOut: 0,
  }).current

  const {
    animatedValue: placeholderOpacity,
    fadeIn: fadeInPlaceholder,
    fadeOut: fadeOutPlaceholder,
  } = useFadeAnimation(placeholderFadeParams)

  useEffect(() => {
    if (isEmptyValue(valuePhone)) {
      fadeOutPlaceholder()

      return
    }

    fadeInPlaceholder()
  }, [valuePhone])

  const selectOption = (option: IValueCodeArea) => {
    if (!option.phoneCode) return
    if (onChangeCodeArea) {
      onChangeCodeArea(option)
    }
  }

  const onLayout = (event: {
    nativeEvent: {
      layout: React.SetStateAction<{
        height: number
        width: number
        x: number
        y: number
      }>
    }
  }) => {
    setContainerLayout(event.nativeEvent.layout)
  }

  const ContentIcon = () => (
    <View style={styles.iconLeft} testID='icon_flag'>
      <FlagCountry country={valueCodeArea} />
      <View style={styles.iconRightItem}>
        <CommonIcon name={'CHEVRON-DOWN'} size={SIZES.MEDIUM} />
      </View>
      <Text style={styles.codeArea}>{`+${valueCodeArea?.phoneCode}`}</Text>
    </View>
  )

  const renderLabel = (label: string) => {
    if (containerLayout?.width <= 0) return label

    const result = containerLayout?.width / MAGIC_SPACE_DROPDOWN

    return result > label?.length
      ? label
      : `${label?.substring(
          0,
          Platform.OS !== 'web' ? result - MAGIC_RESULT_MOBILE : result
        )}...`
  }

  const leftIcon = (
    <MenuDefault anchor={<ContentIcon />} disabled={!editable || disabled}>
      <FlatList
        testID='phone_country'
        data={countries}
        style={styles.modalListContainer}
        renderItem={({ item }) => (
          <TouchableRipple
            onPress={() => {
              if (!item.disabled) {
                selectOption(item)
              }
            }}
            style={styles.modalListOption}
            rippleColor={onPressedColor}
            underlayColor={onPressedColor}
          >
            <View style={styles.contentFlatList}>
              <View>
                <FlagCountry country={item} />
              </View>
              <View style={styles.contentText}>
                <Text style={styles.textPhone}>+{item.phoneCode}</Text>
                <Text
                  style={
                    item.disabled
                      ? styles.textDisabled
                      : styles.modalListOptionText
                  }
                  numberOfLines={1}
                >
                  {item.name}
                </Text>
              </View>
            </View>
          </TouchableRipple>
        )}
        keyExtractor={(item, index) => `SELECT_OPTION_${index}`}
      />
    </MenuDefault>
  )

  return (
    <View style={containerStyle} onLayout={onLayout}>
      <Animated.View
        style={[
          styles.placeholderContainer,
          {
            opacity: placeholderOpacity,
          },
        ]}
      >
        <Text
          style={[
            styles.placeholder,
            touched && error ? styles.placeholderError : {},
            isFocused ? styles.focusedPlaceholder : {},
            disabled ? styles.placeholderDisabled : {},
          ]}
        >
          {label ?? placeholder}
        </Text>

        <View style={styles.placeholderBackground}></View>
      </Animated.View>
      <Input
        id={id}
        testID='phone_number'
        name={name}
        inputContainerStyle={[
          styles.inputContainer,
          touched && error ? styles.borderError : {},
          isFocused ? styles.focusedInput : {},
        ]}
        inputStyle={[styles.input, inputStyle]}
        leftIcon={leftIcon}
        value={renderLabel(valuePhone)}
        ref={inputRef}
        editable={editable}
        disabled={disabled}
        placeholder={placeholder}
        placeholderTextColor={blackMediumEmphasis}
        errorStyle={styles.textError}
        errorMessage={touched && error}
        rightIconContainerStyle={styles.rightIconStyle}
        keyboardType='numeric'
        onChangeText={onChangePhoneNumber}
        onBlur={onBlur}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: white,
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: borderInput,
    borderRadius: 2,
    height: 62,
    paddingHorizontal: 16,
    marginHorizontal: -10,
    zIndex: 1,
  },
  borderError: {
    borderColor: redError,
  },
  textError: {
    color: redError,
    fontSize: 12,
    marginLeft: 9,
  },
  input: {
    fontSize: 15,
  },
  focusedInput: {
    borderColor: primary500,
  },
  placeholderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2,
    marginLeft: 10,
    marginTop: -9,
    opacity: 0,
  },
  placeholder: {
    fontSize: 12,
    color: blackHighEmphasis,
    zIndex: 4,
    marginHorizontal: 5,
  },
  placeholderError: {
    color: redError,
  },
  placeholderDisabled: {
    color: placeholderDisabled,
  },
  focusedPlaceholder: {
    color: primary500,
  },
  placeholderBackground: {
    backgroundColor: white,
    position: 'absolute',
    width: '100%',
    height: 4,
    zIndex: 3,
    marginTop: 8.75,
  },
  modalListContainer: {
    maxHeight: 300,
  },
  modalListOption: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 5,
  },
  modalListOptionText: {
    fontSize: 15,
    color: blackHighEmphasis,
  },
  textDisabled: {
    fontSize: 15,
    color: buttonDisabled,
  },
  modalBackScreen: {
    backgroundColor: redError,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  rightIconStyle: {
    position: 'absolute',
    right: 15,
    backgroundColor: white,
  },
  contentFlatList: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 12,
  },
  contentText: {
    borderBottomColor: grayDDD,
    borderBottomWidth: 1,
    marginLeft: 32,
    paddingVertical: 16,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  textPhone: {
    fontSize: 16,
    fontWeight: '600',
    color: blackHighEmphasis,
    marginRight: 8,
  },
  textFlagCountry: {
    fontSize: 18,
  },
  iconLeft: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconRightItem: {
    paddingRight: 6,
    marginLeft: 6,
    borderRightColor: grayDDD,
    borderRightWidth: 1,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  codeArea: {
    color: blackHighEmphasis,
    marginLeft: 4,
    fontWeight: '500',
    fontSize: 15,
  },
  imgFlag: {
    width: 30,
    height: 20,
  },
})
