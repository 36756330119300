import React, { useContext } from 'react'
import { View, Platform } from 'react-native'
import { PropTypes } from 'prop-types'
import ModalEvidence from '@components/common/ModalEvidence'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { LanguageContext } from '@contextState/language'
import { primary500, blackDisabled } from '@styles/palette'
import ACTIVITY_TYPES from '@constants/activityTypes'
import { findEvidenceCft } from '../utils'
import { EEvidenceConcepts } from '@common/enum'

export const AddEvidence = ({
  isAddEvidenceVisible = true,
  isModalEvidenceVisible,
  toggleModalEvidences,
  closeModalEvidences,
  handleSelectEvidence,
  lots,
  tag,
  itemSelected,
  values,
  setDontShowGenerateCft,
  evidenceCft,
}) => {
  if (!isAddEvidenceVisible) {
    return false
  }

  const { t } = useContext(LanguageContext)
  const { evidences, dataForCFT } = values

  const allowedFormats = [
    '.jpg',
    '.jpeg',
    '.png',
    '.heic',
    '.pdf',
    '.doc',
    '.docx',
    '.txt',
    '.zip',
    '.xls',
    '.xlsx',
  ]

  const filterEvidences = (evidencesTypes) => {
    if (tag !== ACTIVITY_TYPES.ACT_AGREEMENTS.key) {
      return evidencesTypes
    }
    const hasCftEvidence = findEvidenceCft(evidences, evidenceCft)
    if (hasCftEvidence || dataForCFT) {
      if (hasCftEvidence) {
        setDontShowGenerateCft(true)
      }
      return evidencesTypes.filter(
        (evidenceType) => evidenceType.code !== EEvidenceConcepts['0013']
      )
    }
    return evidencesTypes
  }

  return (
    <View>
      <ButtonPrimary
        text={t('VIEWS').ACTIVITY_CREATE.EVIDENCE.TEXT_1}
        onPress={toggleModalEvidences}
        backgroundColor={'transparent'}
        disabled={lots.value.length <= 0}
        colorText={lots.value.length <= 0 ? blackDisabled : primary500}
      />
      <ModalEvidence
        showCameraRollOption={Platform.OS !== 'web'}
        visible={isModalEvidenceVisible}
        onSelect={handleSelectEvidence}
        handleClose={closeModalEvidences}
        allowedFormats={allowedFormats}
        tag={tag}
        itemSelected={itemSelected}
        filterEvidences={filterEvidences}
      />
    </View>
  )
}

AddEvidence.propTypes = {
  isAddEvidenceVisible: PropTypes.bool.isRequired,
  isModalEvidenceVisible: PropTypes.bool.isRequired,
  toggleModalEvidences: PropTypes.func.isRequired,
  closeModalEvidences: PropTypes.func.isRequired,
  handleSelectEvidence: PropTypes.func.isRequired,
  lots: PropTypes.object.isRequired,
  tag: PropTypes.string,
  itemSelected: PropTypes.object,
}
