/*
  Index reducer, combine all app reducer.
 */
import { combineReducers } from 'redux'

import collaborators from './collaborators'
import draftCrop from './draftCrop'
import verificationTypes from './verificationTypes'
import lots from './lots'
import selectFileBinary from './selectFileBinary'
import collaboratorsCompany from './collaboratorsCompany'
import farm from './farm'
import activities from './activities'
import {
  webViewReducer,
  selectionPageReducer,
  connectionReducer,
  authReducer,
  activityTemporalDataReducer,
  notificationsReducer,
  cropTypesReducer,
  typeAgreementsReducer,
  activityTypesReducer,
  storageTypesReducer,
  unitTypesReducer,
  unitTypeVolumeReducer,
  irrigationDataReducer,
  countriesReducer,
  rolesReducer,
} from '../slices'

export default combineReducers({
  collaborators,
  draftCrop,
  verificationTypes,
  lots,
  selectFileBinary,
  collaboratorsCompany,
  auth: authReducer,
  farm,
  activities,
  webViewReducer,
  selectionPageReducer,
  connectionReducer,
  activityTemporalData: activityTemporalDataReducer,
  notifications: notificationsReducer,
  cropTypesReducer,
  activityTypesReducer,
  typeAgreementsReducer,
  storageTypesReducer,
  unitTypesReducer,
  unitTypeVolumeReducer,
  irrigationDataReducer,
  countriesReducer,
  rolesReducer,
})
