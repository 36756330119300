import { Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { makeRequest } from '@common/services'
import useOfflineTypes from '@offline/queries/types'
import { GlobalStateInterface } from '@store/interfaces'
import { setCountries } from '@store/slices/countries.slice'

export const useCountries = () => {
  const dispatch = useDispatch()
  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )

  const { storeType, getTypes } = useOfflineTypes()
  const { countries } = useSelector(
    (state: GlobalStateInterface) => state.countriesReducer
  )

  const fetchCountries = async () => {
    let data = []

    if (isConnected) {
      const response = await makeRequest({ url: 'commons/countries' })
      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('countries', data)
      }
    } else {
      data = await getTypes('countries')
    }

    dispatch(setCountries(data))
  }

  const allCountryData = (countryId: string) => {
    return countries.find((element: any) => element._id === countryId)
  }

  return {
    fetchCountries,
    allCountryData,
  }
}
